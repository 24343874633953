import React from 'react';
import Post from './Post'
import Spinner from './Spinner';

const Posts = (props) => {
  const { posts, isLoading } = props;
  
  if (!posts || posts.length === 0) {
    return <Spinner />;
  }
  
  return (
    <div className="container">
        <h2 className='list-head'>Posts</h2>
        {isLoading && !posts.map(() => {
          console.log("LOADING");
          return(
            <Spinner />
          );
        })
        }
        {!isLoading && posts.map((post) => {
          console.log("NOT LOADING");
            return (
              <Post key={post.id} post={post}/>
            );
        })
        }
    </div>
  );
};
export default Posts;
